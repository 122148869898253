import {
  BadgeConfig,
  CardData,
  CardDataWithHeroExtras,
} from 'flows/core/types/cards';
import { ItemKinds } from 'types';
import { DataForCards } from 'flows/core/hooks/use-data-for-cards';
import { enabledItemDefOfKind, itemOfKind, Badges } from './utils';

const twoBedImg = '/images/boxes/two_bed.png';

const KIND = ItemKinds.PACKING_SUPPLIES;

const PACKING_COMMON_STATIC_DATA = {
  subtype: 'IMAGE',
  icon: 'BOX',
  title: 'Get boxes and supplies',
  action: {
    type: 'internal',
    route: '/packing-supplies',
  },
} as const;

const PACKNG_PROMO_STATIC_DATA = {
  ...PACKING_COMMON_STATIC_DATA,
  description: 'FREE delivery in 3 business days.',
  image: {
    url: twoBedImg,
    alt: 'Moving Kits',
  },
} as const;

const PACKING_SUPPLIES_INITIATE_STATIC_DATA = {
  ...PACKING_COMMON_STATIC_DATA,
  subtype: 'BOXES',
  description:
    'Free shipping on moving kits with all the packing materials you need.',
} as const;

const PACKING_HERO_EXTRAS = {
  initiate: {
    heading: 'Order boxes, delivered right to your door.',
    ctaText: 'Order a moving kit',
  },
  draft: {
    heading: 'Get a head start on packing for your move.',
    ctaText: 'Order a moving kit',
  },
} as const;

export const statusToBadge = (status?: string): BadgeConfig | undefined => {
  if (status === 'started') {
    return Badges.RECENTLY_VIEWED;
  }
  if (status === 'reserved') {
    return Badges.ALMOST_DONE;
  }
  if (status === 'completed' || status === 'validating') {
    return Badges.DONE;
  }
  return undefined;
};

export const makeTodayTabPackingSuppliesCards = ({
  itemDefinitions,
  items,
}: DataForCards): CardDataWithHeroExtras[] => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const packingSupplyItems = items.filter(itemOfKind(KIND));

  if (itemDef) {
    return [
      {
        type: 'PROMOTED',
        data: {
          ...PACKNG_PROMO_STATIC_DATA,
          // TODO: override title here with dynamic data
          // TODO: we wait for "/packing-supplies" to be ready
          badge: statusToBadge(packingSupplyItems[0]?.status),
        },
        heroExtras:
          PACKING_HERO_EXTRAS[packingSupplyItems.length ? 'draft' : 'initiate'],
        identifier: 'boxes_promo',
        importanceScore: 390_000,
      },
    ];
  }
  return [];
};

export const makeMovingTabPackingSuppliesCards = (): CardData => {
  return {
    type: 'INITIATE',
    data: {
      ...PACKING_SUPPLIES_INITIATE_STATIC_DATA,
    },
    identifier: 'boxes_initiate',
    importanceScore: 0,
  };
};
