import { ItemStatuses } from 'constants/statuses';
import {
  VehicleRegistrationInformantData,
  VehicleRegistrationSubmission,
} from '../types';

const fullLinkOnlyRegex =
  /\[(.+)\]\((https?:\/\/[^\s]+)(?: "(.+)")?\)|(https?:\/\/[^\s]+)/;

export const getVehicleLink = ({ page }: { page?: any }): string => {
  if (page?.linkout_url || page?.registration_url) {
    return page?.linkout_url || page?.registration_url || '';
  } else {
    const instructions = page?.questions?.[1]?.instructions;
    return instructions?.match(fullLinkOnlyRegex)?.[2] || '';
  }
};

export const getVehicleDirections = ({
  page,
}: {
  page?: { directions?: string };
}): string => {
  return page?.directions || '';
};

const formatHasVehicleForInformant = (arg?: boolean | string) => {
  if (arg === '' || typeof arg === 'undefined') return '';
  return arg ? 'true' : 'false';
};

export const formatSubmissionForInformant = (
  submission: VehicleRegistrationSubmission
) => {
  const informantValues =
    submission?.vehicles?.map((s) => {
      return {
        has_vehicle: formatHasVehicleForInformant(submission.has_vehicle),
        vehicle_info: {
          id: s.id,
          make: s.make || '',
          model: s.model || '',
          color: s.color || '',
          license_number: s.license_number || '',
          license_state: s.license_state || '',
          year: s.year || '',
          notes: s.notes || '',
          is_selected: s.is_selected || false,
        },
      };
    }) || [];
  return informantValues;
};

export const getBaseInitialValues = (
  hasVehicle?: boolean | string,
  currentVehicleNumber = 1
) => {
  return {
    has_vehicle: formatHasVehicleForInformant(hasVehicle),
    vehicle_info: {
      id: currentVehicleNumber,
      make: '',
      model: '',
      color: '',
      license_number: '',
      license_state: '',
      year: '',
      notes: '',
    },
  };
};

export const getFormValues = (
  submission: VehicleRegistrationInformantData[],
  hasVehicle?: boolean | string
): VehicleRegistrationInformantData => {
  if (submission.length === 0) {
    return getBaseInitialValues(hasVehicle);
  }
  const values = submission.find((s) => {
    return s.vehicle_info?.is_selected;
  });

  return values ? values : getBaseInitialValues(true, submission.length + 1);
};

export const getInitialValues = (
  submission: VehicleRegistrationSubmission
): VehicleRegistrationInformantData => {
  const values = getFormValues(
    formatSubmissionForInformant(submission),
    submission.has_vehicle
  );

  return values;
};

export const isVehiclesHostedAbandoned = (
  submission?: VehicleRegistrationSubmission,
  status?: ItemStatuses
) => {
  const vehicles = submission?.vehicles || [];
  return vehicles.length > 0 && status === ItemStatuses.STARTED;
};
