import { ItemStatuses } from 'constants/statuses';
import { DataForCards } from 'flows/core/hooks/use-data-for-cards';
import {
  BadgeConfig,
  CardData,
  CardDataWithHeroExtras,
} from 'flows/core/types/cards';
import { EssentialCards } from 'flows/core/types/layouts';
import { REJECT_PAGE_ITEM_KINDS_LIST } from 'flows/resident-onboarding/constants/constants';
import {
  PetsItemConfiguration,
  RejectPageItemKind,
} from 'flows/resident-onboarding/constants/types';
import { makeLinkoutCardData } from 'flows/resident-onboarding/hooks/use-linkout-config';
import { getPetsData } from 'flows/resident-onboarding/utils/pets-metadata';
import { ItemDefinition, ItemKinds } from 'types';
import { UtilityKinds } from 'types/../../__generated__/globalTypes';
import { Item } from 'types/item';
import { STATIC_LOGOS } from './utility';
import {
  Badges,
  enabledItemDefOfKind,
  isCompleted,
  isPetsAbandonedOrAllAnswersComplete,
  isTaskCompletedOrValidating,
  isTaskRejected,
  itemHasPrefix,
  itemOfKind,
  lookupImportanceByCardStatus,
} from './utils';
import { isVehiclesHostedAbandoned } from 'flows/resident-onboarding/utils/vehicles-metadata';

const URL_PREFIX = '/resident-onboarding';

const STATUS_KINDS = {
  info_requested: 'info_requested',
  completed: 'completed',
  validating: 'validating',
  started: 'started',
};

const PETS_HERO_EXTRAS = {
  initiate: {
    heading: 'Next up: confirm your pet details.',
    ctaText: 'Get started',
  },
  draft: {
    heading:
      'Almost there! A few more details to register Fido, Noodle, and Pookie.',
    ctaText: 'Update details',
  },
} as const;

const VEHICLES_HERO_EXTRAS = {
  initiate: {
    heading: 'Next up: confirm your vehicle information.',
    ctaText: 'Confirm vehicle',
  },
  draft: {
    heading: 'Almost there! A few more steps to register your car.',
    ctaText: 'Confirm vehicle',
  },
} as const;

const MOVE_COST_HERO_EXTRAS = {
  initiate: {
    heading: 'Next up: confirm your move-in cost payment.',
    ctaText: 'Confirm payment details',
  },
  ctaText: {
    heading: 'Almost there! A few more steps for move-in costs.',
    ctaText: 'Confirm payment details',
  },
};

const UTILITIES_HERO_EXTRAS = {
  initiate: {
    heading: 'Upload proof of service and check providers.',
    ctaText: 'Get started',
  },
  draft: {
    heading: 'Almost there! A few more steps to set up utilities.',
    ctaText: 'Confirm utilities',
  },
};

export const statusToBadge = (
  status?: string,
  isUtilities = false,
  isMoveInCostsUpdated = false
): BadgeConfig | undefined => {
  if (status === 'info_requested') {
    return Badges.NEEDS_MORE;
  }
  if (status === 'validating') {
    return Badges.IN_REVIEW;
  }
  if (status === 'completed') {
    return Badges.DONE;
  }
  if (isUtilities && status === 'started') {
    return Badges.ALMOST_DONE;
  }
  if (isMoveInCostsUpdated && status === 'started') {
    return Badges.UPDATED;
  }
  // for abandoned pets flow
  if (status === ItemStatuses.ACTION_REQUIRED) {
    return Badges.ALMOST_DONE;
  }
  return undefined;
};

// Updates route of cards based on their status
type UpdateCardRoutePathAttributes = {
  route: string;
  routeParent?: string;
  lastRoute: 'summary' | 'status' | null;
  status: ItemStatuses;
  kind: RejectPageItemKind;
};
const updateCardRoutePath = ({
  route,
  lastRoute,
  status,
  kind,
  routeParent = '',
}: UpdateCardRoutePathAttributes): string => {
  let redirectRoute;
  if (isTaskCompletedOrValidating({ status }))
    redirectRoute = lastRoute ? `${routeParent}${lastRoute}` : '';

  if (isTaskRejected({ status }) && REJECT_PAGE_ITEM_KINDS_LIST.includes(kind))
    redirectRoute = `${routeParent}rejection`;

  if (status === ItemStatuses.ACTION_REQUIRED) {
    redirectRoute = `${routeParent}${lastRoute}`;
  }

  return redirectRoute
    ? `${route}/${redirectRoute}`
    : `${route}/${routeParent}`;
};

export const statusExists = (targetStatus: string) => {
  return (statusArray: string[]) =>
    statusArray.some((status) => status === targetStatus);
};
export const everyStatusIs = (targetStatus: string) => {
  return (statusArray: string[]) =>
    statusArray.every((status) => status === targetStatus);
};

export const everyStatusExists = (targetStatuses: string[]) => {
  return (statusArray: string[]) =>
    targetStatuses.every((status) => statusArray.includes(status));
};

// Needs More
const infoRequested = statusExists('info_requested');

// Done
const allComplete = everyStatusIs('completed');

// Almost Done
const hasItemsInReviewAndToComplete = everyStatusExists([
  'started',
  'validating',
]);
const hasItemsCompletedAndToComplete = everyStatusExists([
  'started',
  'completed',
]);
const hasItemsInReviewAndToCompleteAndCompleted = everyStatusExists([
  'completed',
  'started',
  'validating',
]);

// In Review
const allInReview = everyStatusIs('validating');

const hasItemInReviewAndCompleted = everyStatusExists([
  'validating',
  'completed',
]);

export const reduceToStatus = (statuses: string[]): string | undefined => {
  if (infoRequested(statuses)) return STATUS_KINDS.info_requested;
  if (allComplete(statuses)) return STATUS_KINDS.completed;

  if (
    hasItemsInReviewAndToComplete(statuses) ||
    hasItemsCompletedAndToComplete(statuses) ||
    hasItemsInReviewAndToCompleteAndCompleted(statuses)
  )
    return STATUS_KINDS.started;

  if (allInReview(statuses) || hasItemInReviewAndCompleted(statuses))
    return STATUS_KINDS.validating;

  return undefined;
};

export const makePetsTodayTabCards = ({
  itemDefinitions,
  items,
}: DataForCards): CardDataWithHeroExtras[] => {
  const KIND = ItemKinds.RESIDENT_ONBOARDING_PET_REGISTRATION;
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const [petItem] = items.filter(itemOfKind(KIND));

  const { petsData } = getPetsData(
    petItem,
    itemDef?.page as PetsItemConfiguration
  );
  const status = isPetsAbandonedOrAllAnswersComplete(petsData, petItem?.status)
    ? ItemStatuses.ACTION_REQUIRED
    : petItem?.status;

  if (itemDef) {
    return [
      {
        type: 'PROMOTED',
        data: {
          subtype: 'IMAGE',
          title: 'Register your pets',
          icon: 'DOG',
          image: {
            url: '/images/resident-onboarding/pets.svg',
            alt: 'Illustration of cats, dogs, and birds',
            bg: 'reviewCardBackground',
          },
          action: {
            type: 'internal',
            route: updateCardRoutePath({
              route: `${URL_PREFIX}/pets`,
              lastRoute: 'summary',
              status,
              kind: petItem?.kind,
            }),
          },
          badge: statusToBadge(status),
        },
        heroExtras:
          PETS_HERO_EXTRAS[status !== 'started' ? 'draft' : 'initiate'],
        identifier: 'res_onboarding_pets-promo',
        importanceScore: lookupImportanceByCardStatus('PET', status),
      },
    ];
  }

  return [];
};

// find both linkout and hosted vehicle items and return the enabled item data
export const getVehicleItemData = ({
  itemDefinitions,
  items,
}: DataForCards): {
  itemDef: ItemDefinition | undefined;
  vehicleItem: any;
  routePrefix: string;
  status: ItemStatuses;
} => {
  const KIND = ItemKinds.RESIDENT_ONBOARDING_VEHICLE_REGISTRATION;
  const HOSTED_KIND = ItemKinds.RESIDENT_ONBOARDING_VEHICLE_REGISTRATION_HOSTED;
  const itemDef = itemDefinitions.find(
    enabledItemDefOfKind([HOSTED_KIND, KIND])
  );

  const itemWithEnabledConfig =
    itemDef?.code === HOSTED_KIND ? HOSTED_KIND : KIND;
  const routePrefix = itemDef?.code === HOSTED_KIND ? 'hosted/' : '';

  const [vehicleItem] = items.filter(itemOfKind(itemWithEnabledConfig));

  const status = isVehiclesHostedAbandoned(
    vehicleItem?.metadata?.submission,
    vehicleItem?.status
  )
    ? ItemStatuses.ACTION_REQUIRED
    : vehicleItem?.status;

  return { itemDef, vehicleItem, routePrefix, status };
};

export const makeVehicleTodayTabCards = ({
  itemDefinitions,
  items,
}: DataForCards): CardDataWithHeroExtras[] => {
  const { vehicleItem, itemDef, routePrefix, status } = getVehicleItemData({
    itemDefinitions,
    items,
  });

  if (itemDef) {
    return [
      {
        type: 'PROMOTED',
        data: {
          subtype: 'IMAGE',
          title: 'Register your vehicle',
          icon: 'CAR',
          image: {
            url: '/images/resident-onboarding/vehicles.svg',
            alt: 'Illustration of cars',
            bg: 'reviewCardBackground',
          },
          action: {
            type: 'internal',
            route: updateCardRoutePath({
              route: `${URL_PREFIX}/vehicles`,
              routeParent: routePrefix,
              lastRoute: 'summary',
              status: status,
              kind: vehicleItem?.kind,
            }),
          },
          badge: statusToBadge(status),
        },
        heroExtras: VEHICLES_HERO_EXTRAS[vehicleItem ? 'initiate' : 'draft'],
        identifier: 'res_onboarding_vehicle-promo',
        importanceScore: lookupImportanceByCardStatus(
          'CAR',
          vehicleItem?.status
        ),
      },
    ];
  }
  return [];
};

export const makeMoveCostTodayTabCards = ({
  itemDefinitions,
  items,
}: DataForCards): CardDataWithHeroExtras[] => {
  const KIND = ItemKinds.RESIDENT_ONBOARDING_MOVE_IN_COSTS;
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));

  if (itemDef) {
    const [moveCostItem] = items.filter(itemOfKind(KIND));
    const isMoveCostsUpdated = moveCostItem?.metadata?.page?.costs_updated_at;
    return [
      {
        type: 'PROMOTED',
        importanceScore: lookupImportanceByCardStatus(
          'MOVE_IN_COSTS',
          moveCostItem?.status
        ),
        identifier: 'res_onboarding_move_costs-promo',
        data: {
          subtype: 'IMAGE',
          title: `Pay ${itemDef.entity.name} your move-in costs`,
          icon: 'DOLLAR',
          image: {
            url: '/images/resident-onboarding/move-costs.svg',
            alt: 'Illustration of house and currency',
            bg: 'reviewCardBackground',
          },
          action: {
            type: 'internal',
            route: updateCardRoutePath({
              route: `${URL_PREFIX}/move-costs`,
              lastRoute: 'summary',
              status: moveCostItem?.status,
              kind: moveCostItem?.kind,
            }),
          },
          badge: statusToBadge(moveCostItem?.status, false, isMoveCostsUpdated),
        },
        heroExtras: MOVE_COST_HERO_EXTRAS[moveCostItem ? 'initiate' : 'draft'],
      },
    ];
  }
  return [];
};

interface Utils {
  type: UtilityKinds.water | UtilityKinds.gas | UtilityKinds.electric;
}

export const UtilityItemKinds = {
  [ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_ELECTRIC]: UtilityKinds.electric,
  [ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_WATER]: UtilityKinds.water,
  [ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_GAS]: UtilityKinds.gas,
};

const UTILITY_KINDS = Object.keys(UtilityItemKinds);

export const getUtilType = (kind: string): Utils => {
  return UtilityItemKinds[kind];
};

export const getProofOfUtilsRoute = (
  util: Utils | undefined | UtilityKinds,
  isSingleUtility: boolean,
  status: string
) => {
  if (isSingleUtility && isTaskRejected({ status })) {
    return `${URL_PREFIX}/proof-of-utilities/info-requested/${util}`;
  }
  if (isSingleUtility && isTaskCompletedOrValidating({ status })) {
    return `${URL_PREFIX}/proof-of-utilities/proof-submitted/${util}`;
  }
  //  if singleUtil go straight to set-up page, if not go to landing
  return `${URL_PREFIX}/proof-of-utilities${
    isSingleUtility ? `/set-up/${util}` : ''
  }`;
};

export const makeProofOfUtilitiesCards = ({
  itemDefinitions,
  items,
}: DataForCards): CardDataWithHeroExtras[] => {
  const itemDefs = itemDefinitions.filter(enabledItemDefOfKind(UTILITY_KINDS));
  const utilityItems = items.filter(itemOfKind(UTILITY_KINDS));
  const isSingleUtility = utilityItems.length === 1;
  const util = isSingleUtility ? getUtilType(utilityItems[0].kind) : undefined;

  if (utilityItems.length > 0) {
    const utilityStatus = reduceToStatus(
      utilityItems.map(({ status }) => status)
    );
    const propertyName = itemDefs[0].entity?.name;

    return [
      {
        type: 'PROMOTED',
        data: {
          subtype: 'UTIL',
          title: isTaskRejected({ status: utilityStatus ?? '' })
            ? `${
                propertyName || 'Your building'
              } needs more info about your utilities`
            : `Share your utility accounts with ${
                propertyName || 'your building'
              }`,
          icon: 'LIGHT',
          action: {
            type: 'internal',
            route: getProofOfUtilsRoute(util, isSingleUtility, utilityStatus),
          },
          badge: statusToBadge(utilityStatus, true),
          providers: STATIC_LOGOS,
        },
        heroExtras:
          UTILITIES_HERO_EXTRAS[
            utilityStatus !== 'started' ? 'draft' : 'initiate'
          ],
        identifier: 'res_onboarding_utility-promo',
        importanceScore: lookupImportanceByCardStatus(
          'ROB_UTILITY',
          utilityStatus
        ),
      },
    ];
  }

  return [];
};

export const makeTodayTabResidentOnboardingCards = (
  data: DataForCards
): CardDataWithHeroExtras[] => {
  const isResOnboarding =
    data.items.filter(itemHasPrefix(ItemKinds.RESIDENT_ONBOARDING)).length > 0;
  if (isResOnboarding) {
    return [
      makePetsTodayTabCards(data),
      makeProofOfUtilitiesCards(data),
      makeVehicleTodayTabCards(data),
      makeMoveCostTodayTabCards(data),
    ].flat();
  }
  return [];
};

/** Essential Tab ROB cards  */
const ROB_PETS_COMMON_STATIC_DATA = {
  subtype: 'ROB',
  icon: 'DOG',
  title: 'Register your pets',
  description: 'Confirm whether you have pets moving in.', // default for non upo
  action: {
    type: 'internal',
    route: `${URL_PREFIX}/pets`,
  },
} as const;

const ROB_DOCUMENT_REVIEW_COMMON_STATIC_DATA = {
  subtype: 'ROB',
  icon: 'FILE',
  title: 'Review important documents',
  description: 'Read key guidelines and documents about your community.', //default for non upo
  action: {
    type: 'internal',
    route: `${URL_PREFIX}/document-review`,
  },
} as const;

const ROB_VEHICLE_COMMON_STATIC_DATA = {
  subtype: 'ROB',
  icon: 'CAR',
  title: 'Register your vehicle',
  description: 'Confirm whether you have a vehicle.', // default for non upo
  action: {
    type: 'internal',
    route: `${URL_PREFIX}/vehicles`,
  },
} as const;

const ROB_MOVE_COST_COMMON_STATIC_DATA = {
  subtype: 'ROB',
  icon: 'DOLLAR',
  description:
    'View a summary of your move-in costs and finalize payment details.',
  action: {
    type: 'internal',
    route: `${URL_PREFIX}/move-costs`,
  },
} as const;

const ROB_UTILITIES_COMMON_STATIC_DATA = {
  subtype: 'ROB',
  icon: 'LIGHT',
  description:
    'Review your service providers in one place to set up the essentials.',
  action: {
    type: 'internal',
    route: `${URL_PREFIX}/proof-of-utilities`,
  },
} as const;

const makeInitiateCardJson = (
  itemStatus: string,
  commonData,
  isUtilities = false,
  isMoveInCostsUpdated = false,
  types = [] as string[]
) => {
  return {
    type: 'INITIATE',
    data: {
      ...commonData,
      badge: statusToBadge(itemStatus, isUtilities, isMoveInCostsUpdated),
      types,
    },
  } as const;
};

export const makePetsEssentialsTabCards = ({
  itemDefinitions,
  items,
  isUPO,
}: DataForCards & { isUPO: boolean }): EssentialCards => {
  const KIND = ItemKinds.RESIDENT_ONBOARDING_PET_REGISTRATION;
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const [petItem] = items.filter(itemOfKind(KIND));
  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];

  /* Subheader has different text based on UPO status */
  const subheader = isUPO
    ? 'Complete pet registration or confirm that you do not have any pets to register'
    : 'Confirm whether you have pets moving in.';

  if (itemDef) {
    const { petsData } = getPetsData(
      petItem,
      itemDef?.page as PetsItemConfiguration
    );
    const status = isPetsAbandonedOrAllAnswersComplete(
      petsData,
      petItem?.status
    )
      ? ItemStatuses.ACTION_REQUIRED
      : petItem?.status;

    const cardJson = {
      ...makeInitiateCardJson(status, {
        ...ROB_PETS_COMMON_STATIC_DATA,
        description: subheader,
        required: !!petItem?.metadata?.page?.required,
        action: {
          ...ROB_PETS_COMMON_STATIC_DATA.action,
          route: updateCardRoutePath({
            route: ROB_PETS_COMMON_STATIC_DATA.action.route,
            lastRoute: 'summary',
            status,
            kind: petItem?.kind,
          }),
        },
      }),
      identifier: 'res_onboarding_pets',
      importanceScore: 400_000,
    };

    if (petItem && isCompleted(petItem)) {
      completedCards.push(cardJson);
    } else {
      toDoCards.push(cardJson);
    }
  }
  return { toDoCards, completedCards };
};

export const makeDocumentReviewEssentialsTabCards = ({
  itemDefinitions,
  items,
  isUPO,
}: DataForCards & { isUPO: boolean }): EssentialCards => {
  const KIND = ItemKinds.RESIDENT_ONBOARDING_DOCUMENT_REVIEW;
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const [docReviewItem] = items.filter(itemOfKind(KIND));
  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  const status = docReviewItem?.status;

  /* Subheader has different text based on UPO status */
  const subheader = isUPO
    ? 'Read the guidelines and documents about your community.'
    : 'Read key guidelines and documents about your community.';

  if (itemDef) {
    const cardJson = {
      ...makeInitiateCardJson(status, {
        ...ROB_DOCUMENT_REVIEW_COMMON_STATIC_DATA,
        description: subheader,
        icon: 'FILE',
        action: {
          ...ROB_DOCUMENT_REVIEW_COMMON_STATIC_DATA.action,
          route: updateCardRoutePath({
            route: ROB_DOCUMENT_REVIEW_COMMON_STATIC_DATA.action.route,
            lastRoute: null,
            status,
            kind: docReviewItem?.kind,
          }),
        },
        required: !!docReviewItem?.metadata?.page?.required,
      }),
      identifier: 'res_onboarding_document-review',
      importanceScore: 390_000,
    };

    if (isCompleted(docReviewItem)) {
      completedCards.push(cardJson);
    } else {
      toDoCards.push(cardJson);
    }
  }
  return { toDoCards, completedCards };
};

export const makeVehicleEssentialsTabCards = ({
  itemDefinitions,
  items,
  isUPO,
}: DataForCards & { isUPO: boolean }): EssentialCards => {
  const { vehicleItem, itemDef, routePrefix, status } = getVehicleItemData({
    itemDefinitions,
    items,
  });

  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];

  /* Subheader has different text based on UPO status */
  const subheader = isUPO
    ? 'Confirm vehicle details, or confirm you don’t need to register a car.'
    : 'Confirm whether you have a vehicle.';

  if (itemDef) {
    const cardJson = {
      ...makeInitiateCardJson(status, {
        ...ROB_VEHICLE_COMMON_STATIC_DATA,
        description: subheader,
        required: !!vehicleItem?.metadata?.page?.required,
        action: {
          ...ROB_VEHICLE_COMMON_STATIC_DATA.action,
          route: updateCardRoutePath({
            route: ROB_VEHICLE_COMMON_STATIC_DATA.action.route,
            routeParent: routePrefix,
            lastRoute: 'summary',
            status: status,
            kind: vehicleItem?.kind,
          }),
        },
      }),
      identifier: 'res_onboarding_vehicle-registration',
      importanceScore: 500_000,
    };

    if (isCompleted(vehicleItem)) {
      completedCards.push(cardJson);
    } else {
      toDoCards.push(cardJson);
    }
  }
  return { toDoCards, completedCards };
};

export const makeMoveCostsEssentialsTabCards = ({
  itemDefinitions,
  items,
}: DataForCards): EssentialCards => {
  const KIND = ItemKinds.RESIDENT_ONBOARDING_MOVE_IN_COSTS;
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const [moveCostItem] = items.filter(itemOfKind(KIND));
  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  const isMoveCostsUpdated = moveCostItem?.metadata?.page?.costs_updated_at;

  if (itemDef) {
    const cardJson = {
      ...makeInitiateCardJson(
        moveCostItem?.status,
        {
          ...ROB_MOVE_COST_COMMON_STATIC_DATA,
          title: `Move-in costs`,
          required: !!moveCostItem?.metadata?.page?.required,
          action: {
            ...ROB_MOVE_COST_COMMON_STATIC_DATA.action,
            route: updateCardRoutePath({
              route: ROB_MOVE_COST_COMMON_STATIC_DATA.action.route,
              lastRoute: 'summary',
              status: moveCostItem?.status,
              kind: moveCostItem?.kind,
            }),
          },
        },
        /* isUtilities param should = false */
        false,
        isMoveCostsUpdated
      ),
      identifier: 'res_onboarding_move-costs',
      importanceScore: 1_070_000,
    };

    if (isCompleted(moveCostItem)) {
      completedCards.push(cardJson);
    } else {
      toDoCards.push(cardJson);
    }
  }
  return { toDoCards, completedCards };
};
export const getProofOfUtilsTitle = (
  util: Utils | undefined,
  isSingleUtility: boolean
): string => {
  if (isSingleUtility) return `Connect ${util}`;
  return 'Connect utilities';
};
export const makeUtilitiesEssentialsTabCards = ({
  items,
}: {
  items: Item[];
}) => {
  const utilityItems = items.filter(itemOfKind(UTILITY_KINDS));
  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  const isSingleUtility = utilityItems.length === 1;
  const util = isSingleUtility ? getUtilType(utilityItems[0].kind) : undefined;
  if (utilityItems.length > 0) {
    const utilityStatus = reduceToStatus(
      utilityItems.map(({ status }: Item) => status)
    );
    //if a proof-of-utility is present it is required
    const required = Boolean(
      utilityItems.find((item) => item.metadata?.page?.required)
    );

    const cardJson = {
      ...makeInitiateCardJson(
        utilityStatus as string,
        {
          ...ROB_UTILITIES_COMMON_STATIC_DATA,
          title: getProofOfUtilsTitle(util, isSingleUtility),
          required,
          action: {
            ...ROB_UTILITIES_COMMON_STATIC_DATA.action,
            route: getProofOfUtilsRoute(
              util,
              isSingleUtility,
              utilityStatus as string
            ),
          },
        },
        /* isUtilities param = true */
        true,
        /* isMoveCostUpdated param = false */
        false,
        utilityItems.map((item) => item.type)
      ),
      identifier: 'res_onboarding_utility',
      importanceScore: 800_000,
    };
    if (utilityStatus === 'completed') {
      completedCards.push(cardJson);
    } else {
      toDoCards.push(cardJson);
    }
  }
  return { toDoCards, completedCards };
};

const linkoutTypes = [
  'resident_onboarding-resident-link',
  'resident_onboarding-key-pickup',
  'resident_onboarding-loading-dock-reservation',
  'resident_onboarding-elevator-scheduling',
  'resident_onboarding-packages-and-deliveries',
  'resident_onboarding-security-deposit-alternative',
  'resident_onboarding-download-community-app',
  'resident_onboarding-pet-screening',
];

export const filterByKinds = (kindsArr: string[], key: string) => {
  return (item: any) => kindsArr.includes(item[key]);
};

// const filterItemDefsByLinkout = filterByKinds(linkoutTypes, 'code');
const filterItemsByLinkoutTypes = filterByKinds(linkoutTypes, 'type');

type Metadata = {
  page: { required: boolean };
};

export const getLinkoutActionUrl = (status: string, flowSuffix: string) => {
  const hasPrefacingQuestion = flowSuffix === 'security-deposit-alternative';
  if (
    status === STATUS_KINDS.validating ||
    status === STATUS_KINDS.completed ||
    status === STATUS_KINDS.info_requested
  ) {
    return `${URL_PREFIX}/${flowSuffix}/summary`;
  }
  if (hasPrefacingQuestion) return `${URL_PREFIX}/${flowSuffix}/intro`;
  return `${URL_PREFIX}/${flowSuffix}/register`;
};

export const makeLinkoutEssentialsTabCards = (
  data: DataForCards,
  isUPO: boolean
) => {
  const todoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  const linkoutItems = data.items.filter(filterItemsByLinkoutTypes);

  if (linkoutItems.length > 0) {
    linkoutItems.forEach(
      ({
        kind,
        status,
        metadata,
      }: {
        kind: string;
        status: string;
        metadata: Metadata;
      }) => {
        const flowSuffix = kind.split('resident_onboarding-')[1];
        // const currItemDef = linkoutItemDefs.find(({code}) => code === kind);
        const linkoutCardData = makeLinkoutCardData(flowSuffix, isUPO);
        const cardJson = {
          ...makeInitiateCardJson(status, {
            type: 'INITIATE',
            subtype: 'ROB',
            icon: linkoutCardData.icon,
            title: linkoutCardData.title,
            description: linkoutCardData.description,
            action: {
              type: 'internal',
              route: getLinkoutActionUrl(status, flowSuffix),
            },
            required: Boolean(metadata?.page?.required),
          }),
          identifier: `res_onboarding_${flowSuffix}`,
          importanceScore: 750_000,
        };
        if (isCompleted({ status })) {
          completedCards.push(cardJson);
        } else {
          todoCards.push(cardJson);
        }
      }
    );
  }
  return { toDoCards: todoCards, completedCards };
};

const CUSTOM_TASK_PREFIX = 'resident_onboarding-custom_task';

export const makeCustomTaskEssentialsTabCards = (data: DataForCards) => {
  const todoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  const customTaskItems = data.items.filter((item) =>
    item.type.startsWith(CUSTOM_TASK_PREFIX)
  );
  if (customTaskItems.length > 0) {
    customTaskItems.forEach(({ kind, status, metadata }) => {
      const flowSuffix = kind.split('resident_onboarding-')[1];
      const customTaskCardData = {
        title: metadata?.page.name,
        description: metadata?.page.description,
        icon: 'BUILDING',
      };

      const cardJson = {
        ...makeInitiateCardJson(status, {
          type: 'INITIATE',
          subtype: 'ROB',
          icon: customTaskCardData.icon,
          title: customTaskCardData.title,
          description: customTaskCardData.description,
          action: {
            type: 'internal',
            route: `${URL_PREFIX}/${flowSuffix}`,
          },
          required: Boolean(metadata?.page?.required),
        }),
        identifier: `res_onboarding_${flowSuffix}`,
        importanceScore: 750_000,
      };
      if (isCompleted({ status })) {
        completedCards.push(cardJson);
      } else {
        todoCards.push(cardJson);
      }
    });
  }

  return { toDoCards: todoCards, completedCards };
};

export const makeEssentialsTabResidentOnboardingCards = (
  data: DataForCards,
  isUPO: boolean,
  customTasksEnabled: boolean
) => {
  const isResOnboarding =
    data.items.filter(itemHasPrefix(ItemKinds.RESIDENT_ONBOARDING)).length > 0;
  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  /* Note: when isUPO is used, it has different header or subheader
  depending on UPO status */
  if (isResOnboarding) {
    const allCards = [
      makePetsEssentialsTabCards({ ...data, isUPO }),
      makeVehicleEssentialsTabCards({ ...data, isUPO }),
      makeMoveCostsEssentialsTabCards(data),
      makeUtilitiesEssentialsTabCards(data),
      makeLinkoutEssentialsTabCards(data, isUPO),
      makeDocumentReviewEssentialsTabCards({ ...data, isUPO }),
    ];
    if (customTasksEnabled) {
      allCards.push(makeCustomTaskEssentialsTabCards(data));
    }
    const allTodos = allCards.map((flow) => flow.toDoCards).flat();
    const allCompleted = allCards.map((flow) => flow.completedCards).flat();

    return { toDoCards: allTodos, completedCards: allCompleted };
  }
  return { toDoCards, completedCards };
};
