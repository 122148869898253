import {
  CardDataWithHeroExtras,
  ReceiptPayload,
  BadgeConfig,
  CardData,
} from 'flows/core/types/cards';
import { ItemKinds } from 'types';
import {
  DataForCards,
  VerticalCardDetails,
} from 'flows/core/hooks/use-data-for-cards';
import { deepLinkStatuses } from 'flows/core/hooks/constants';
import sortBy from 'lodash/sortBy';
import { formatDate } from 'utils/date';
import { EssentialCards } from 'flows/core/types/layouts';
import { FormType } from 'flows/forward-mail/types';
import { formTypeConst } from 'flows/forward-mail/schemas/data-schema';
import {
  enabledItemDefOfKind,
  isFYMCompleted,
  itemOfKind,
  Badges,
} from './utils';

const KIND = ItemKinds.FORWARD_MAIL;

const FORWARD_MAIL_COMMON_STATIC_DATA = {
  subtype: 'FYM',
  title: 'Forward your mail',
  icon: 'ENVELOPE',
  action: {
    type: 'internal',
    route: 'forward-mail',
  },
} as const;

const FORWARD_MAIL_PROMO_STATIC_DATA = {
  ...FORWARD_MAIL_COMMON_STATIC_DATA,
  image: {
    url: 'images/postal-service-logo.png',
    alt: 'USPS logo',
  },
} as const;

const FORWARD_MAIL_INITIATE_STATIC_DATA = {
  ...FORWARD_MAIL_COMMON_STATIC_DATA,
  description:
    'Don’t let your mail get lost. Update your address with USPS in minutes.',
} as const;

const FORWARD_MAIL_HERO_EXTRAS = {
  initiate: {
    heading: 'Forward your mail quickly and easily.',
    ctaText: 'Get started',
  },
  draft: {
    heading: 'Complete mail forwarding by verifying your identity.',
    ctaText: 'Forward mail',
  },
} as const;

export const statusToImportance = (status?: string) => {
  if (status === 'unverified') {
    return 1_800_001;
  }
  if (status === 'completed' || status === 'validating') {
    return 0;
  }
  return 100_000;
};

export const statusToBadge = (status?: string): BadgeConfig | undefined => {
  if (status === 'started') {
    return Badges.RECENTLY_VIEWED;
  }
  if (status === 'unverified') {
    return Badges.ALMOST_DONE;
  }
  if (status === 'cancelled') {
    return Badges.CANCELLED;
  }
  if (status === 'validating' || status === 'completed') {
    return Badges.DONE;
  }
  if (status === 'information_requested' || status === 'info_requested') {
    return Badges.NEEDS_MORE;
  }
  return undefined;
};

export const makeTodayTabForwardMailCards = ({
  itemDefinitions,
  items,
  user,
}: DataForCards): CardDataWithHeroExtras[] => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const forwardMailItems = sortBy(items.filter(itemOfKind(KIND)), 'createdAt');
  const status = forwardMailItems[0]?.status;

  if (itemDef && !user?.currentMove?.fromAddress?.international) {
    return [
      {
        type: 'PROMOTED',
        data: {
          ...FORWARD_MAIL_PROMO_STATIC_DATA,
          address: {
            // TODO the backend gql type has every value as nullable
            name: `${user?.firstName} ${user?.lastName}`,
            line1: user?.currentMove?.fromAddress.street ?? '',
            line2: user?.currentMove?.fromAddress.unit ?? undefined,
            city: user?.currentMove?.fromAddress.city ?? '',
            state: user?.currentMove?.fromAddress.state ?? '',
            zipcode: user?.currentMove?.fromAddress.postalCode ?? '',
          },
          badge: statusToBadge(status),
        },
        heroExtras:
          FORWARD_MAIL_HERO_EXTRAS[
            forwardMailItems.length ? 'draft' : 'initiate'
          ],
        identifier: 'fym_initiate_promo',
        importanceScore: statusToImportance(status),
      },
    ];
  }
  return [];
};

export const makeEssentialsTabForwardMailCards = ({
  completedFlows,
  itemDefinitions,
  items,
  user,
}: DataForCards & {
  completedFlows: string[];
}): EssentialCards => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const forwardMailItems = sortBy(items.filter(itemOfKind(KIND)), 'createdAt');

  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  const FYMComplete = completedFlows?.includes('forward_mail');

  // International users cannot forward their mail
  if (itemDef && !user?.currentMove?.fromAddress?.international) {
    if (forwardMailItems.length === 0) {
      FYMComplete
        ? completedCards.push({
            type: 'INITIATE',
            data: {
              ...FORWARD_MAIL_INITIATE_STATIC_DATA,
              badge: Badges.DONE,
            },
            identifier: 'fym_initiate_completed',
            importanceScore: 100_000,
          })
        : toDoCards.push({
            type: 'INITIATE',
            data: {
              ...FORWARD_MAIL_INITIATE_STATIC_DATA,
            },
            identifier: 'fym_initiate',
            importanceScore: 100_000,
          });
    } else {
      const forwardMailItem = forwardMailItems[0];
      const { status } = forwardMailItem;
      if (isFYMCompleted(forwardMailItem)) {
        completedCards.push({
          type: 'INITIATE',
          data: {
            ...FORWARD_MAIL_INITIATE_STATIC_DATA,
            badge: statusToBadge(status),
          },
          identifier: `fym_initiate_completed_${forwardMailItem.id}`,
          importanceScore: 100_000,
        });
      } else {
        toDoCards.push({
          type: 'INITIATE',
          data: {
            ...FORWARD_MAIL_INITIATE_STATIC_DATA,
            badge: statusToBadge(status),
          },
          identifier: `fym_initiate_${forwardMailItem.id}`,
          importanceScore: 100_000,
        });
      }
    }
  }
  return {
    toDoCards,
    completedCards,
  };
};

/**
 * @module cardTitle
 * @description Gets the card title according the type of form ('individual' | 'family' | 'business').
 * @param {Object} item The actual item user data.
 * @param {string} companyName The company name.
 * @param {FormType} formType The form type ('individual' | 'family' | 'business').
 * @returns {string}
 */
export const cardTitle = (
  { first: firstName, last: lastName }: { first: string; last: string },
  companyName: string,
  formType?: FormType
) => {
  let title = `${firstName} ${lastName}`;

  switch (formType) {
    case formTypeConst[1].const:
      title = `${lastName} Family`;
      break;
    case formTypeConst[2].const:
      title = companyName;
      break;
    default:
    // do nothing
  }

  return title;
};

export const makeForwardMailReceiptsForVerticals = ({
  items,
  target,
}: VerticalCardDetails): ReceiptPayload[] => {
  if (items.length === 0) {
    return [];
  }

  const forwardMailItems = sortBy(items.filter(itemOfKind(KIND)), 'createdAt');
  const forwardMailCards: ReceiptPayload[] = [];

  if (forwardMailItems) {
    forwardMailItems.forEach((item) => {
      const { id } = item;
      const formType: FormType = item.form_type;
      const companyName = item.business_name;
      const toAddress = item.to_address;
      const userName = cardTitle(item.name, companyName, formType);
      const startDate = item.start_date;
      // explicitly adding 00:00:00 to prevent creating for a day before date
      const startDateLine = formatDate(
        new Date(`${startDate}T00:00:00`),
        "'Starting on 'MM/dd/yyyy"
      );
      const address1 = toAddress.street;
      const address2 = `${toAddress.city}, ${toAddress.state} ${toAddress.zip}`;
      const badgeDetails = statusToBadge(item.status);

      let targetValue = typeof target === 'string' ? target : target[id];
      if (deepLinkStatuses.includes(item.status)) {
        targetValue = `/forward-mail/receipts/${id}`;
      }

      forwardMailCards.push({
        type: 'RECEIPT',
        data: {
          id,
          subtype: 'BASIC',
          icon: 'ENVELOPE',
          badge: badgeDetails,
          heading: userName,
          description: address1,
          descriptionLine2: address2,
          subtitle: startDateLine,
          action: {
            type: 'internal',
            route: targetValue || '',
          },
        },
        identifier: `receipt_verticals_${id}`,
        importanceScore: 100_000,
      });
    });
  }

  return forwardMailCards;
};
