import {
  format as _format,
  subMonths as _subMonths,
  addMonths as _addMonths,
  addDays as _addDays,
  parseISO as _parseISO,
  // @ts-ignore
} from 'date-fns';

export const subMonths = _subMonths;
export const addMonths = _addMonths;
export const addDays = _addDays;
export const parseISO = _parseISO;

export const DATE_FORMATS = {
  // REF: http://www.iso.org/iso/home/standards/iso8601.htm
  ISO_8601: 'yyyy-MM-dd',
  USA_LONG: 'MM/dd/yyyy',
  DOW_MONTH_DOM: 'EEEE, MMMM d',
};

export function formatDate(date, formatString = DATE_FORMATS.ISO_8601) {
  return _format(date, formatString);
}
