import { Provider } from '../types';

export const PROVIDER_LOGO_BASE_URL =
  'https://res.cloudinary.com/updater-production/image/upload/';

export const SURE_PROVIDER = {
  name: 'Sure',
  description: 'Starts at $8/mo',
  id: 33,
  cta_url: 'https://www.sureapp.com',
  logo_id: 'v1634243690/Property%20Management/sure-logo',
  logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/sure-logo.png',
};

export const INSURANCE_PROVIDERS = [
  {
    name: 'Asset Protect',
    id: 1,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/Asset_Protect_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/Asset_Protect_logo.png',
  },
  {
    name: 'Assurant',
    id: 2,
    cta_url: '',
    logo_id: 'v1468517070/recommended_provider_logo_2056',
    logo: 'http://res.cloudinary.com/updater-production/image/upload/v1468517070/recommended_provider_logo_2056.png',
  },
  {
    name: 'Bader',
    id: 3,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/bader_logo_screenshot',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/bader_logo_screenshot.png',
  },
  {
    name: 'Beecher Carlson',
    id: 4,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/beecher_Carlson_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/beecher_Carlson_logo.png',
  },
  {
    name: 'Bridge Liability Coverage',
    id: 5,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/Bridge_renters_Insurance_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/Bridge_renters_Insurance_logo.png',
  },
  {
    name: 'Brightway Insurance',
    id: 6,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/Brightway_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/Brightway_logo.png',
  },
  {
    name: 'Confirm Insurance',
    id: 37,
    cta_url: 'https://confirminsurance.com',
    logo_id: 'v1661786298/Property%20Management/Confirm%20Insurance%20Logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1661786298/Property%20Management/Confirm%20Insurance%20Logo.png',
  },
  {
    name: 'Community Indemnity Shield',
    id: 7,
    cta_url: '',
    logo_id:
      'v1628534136/Property%20Management/Community_Indemnity_Shield_Logo_verticle',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1628534136/Property%20Management/Community_Indemnity_Shield_Logo_verticle.png',
  },
  {
    name: 'Community Protection Program',
    id: 39,
    cta_url: '',
    logo_id: 'v1662994263/upload/timberland-partners',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1662994263/upload/timberland-partners.png',
  },
  {
    name: 'CoreLogic',
    id: 8,
    cta_url: '',
    logo_id: '/v1595253776/Property/CoreLogic',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1595253776/Property/CoreLogic.png',
  },
  {
    name: 'Deans & Homer',
    id: 9,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/deans-homer_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/deans-homer_logo.png',
  },
  {
    name: 'Effective Coverage',
    id: 10,
    cta_url: '',
    logo_id: '/v1560540991/Property/Screen_Shot_2019-06-14_at_3.35.43_PM',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1560540991/Property/Screen_Shot_2019-06-14_at_3.35.43_PM.png',
  },
  {
    name: 'ePremium',
    id: 11,
    cta_url: '',
    logo_id: 'v1428678470/epremium_insurance_logo_bipoeo',
    logo: 'http://res.cloudinary.com/updater-production/image/upload/v1428678470/epremium_insurance_logo_bipoeo.jpg',
  },
  {
    name: 'eRenterPlan',
    id: 12,
    cta_url: '',
    logo_id: 'v1492120740/preferred_provider_logo_5381',
    logo: 'http://res.cloudinary.com/updater-production/image/upload/v1492120740/preferred_provider_logo_5381.png',
  },
  {
    name: "Farmer's Insurance",
    id: 13,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/Farmers_Insurance_Group_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/Farmers_Insurance_Group_logo.png',
  },
  {
    name: 'First American',
    id: 14,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/first_american_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/first_american_logo.jpg',
  },
  {
    name: 'Fortegra',
    id: 35,
    cta_url: 'https://fortegrarenters.com/',
    logo_id: 'v1657029746/insurance-logos/Fortegra_Logo',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1657029746/insurance-logos/Fortegra_Logo.png',
  },
  {
    name: 'Foxen',
    id: 36,
    cta_url: 'https://www.foxen.com/',
    logo_id: 'v1657029746/insurance-logos/foxen.logo',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1657029746/insurance-logos/foxen.logo.png',
  },
  {
    name: 'Get Covered',
    id: 34,
    cta_url: '',
    logo_id: 'v1649856895/renters_insurance_128127176_Get%20Covered',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1649856895/renters_insurance_128127176_Get%20Covered.png',
  },
  {
    name: 'Jetty',
    id: 15,
    cta_url: '',
    logo_id: '/v1674585192/insurance-logos/jetty-insurance-agency-logo',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1674585192/insurance-logos/jetty-insurance-agency-logo.png',
  },
  {
    name: 'LeaseTerm Solutions',
    id: 16,
    cta_url: '',
    logo_id:
      'v1609189898/Property%20Management/Screen_Shot_2020-12-28_at_4.11.17_PM',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1609189898/Property%20Management/Screen_Shot_2020-12-28_at_4.11.17_PM.png',
  },
  {
    name: 'LeaseTrack',
    id: 38,
    cta_url: 'https://leasetrack.ai/renters/',
    logo_id: 'v1656034654/renters_insurance_125020605_LeaseTrack.png',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1656034654/renters_insurance_125020605_LeaseTrack.png',
  },
  {
    name: 'LeasingDesk',
    id: 17,
    cta_url: '',
    logo_id: 'v1492120740/preferred_provider_logo_5381',
    logo: 'http://res.cloudinary.com/updater-production/image/upload/v1492120740/preferred_provider_logo_5381.png',
  },
  {
    name: 'Lemonade',
    id: 18,
    cta_url: '',
    logo_id:
      'v1620940523/Property%20Management/Screen_Shot_2021-05-13_at_5.08.17_PM',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1620940523/Property%20Management/Screen_Shot_2021-05-13_at_5.08.17_PM.png',
  },
  {
    name: 'Multifamily Insurance Partners',
    id: 19,
    cta_url: '',
    logo_id:
      'v1634243690/Property%20Management/multifamily_insurance_partners_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/multifamily_insurance_partners_logo.png',
  },
  {
    name: 'New Castle Insurance',
    id: 20,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/new_castle_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/new_castle_logo.png',
  },
  {
    name: 'Occupant Shield',
    id: 21,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/occupant_shield_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/occupant_shield_logo.png',
  },
  {
    name: 'PolicyValidator',
    id: 34,
    cta_url: 'https://www.policyvalidator.com',
    logo_id: 'v1654631283/Property%20Management/PVFullLogo1',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1654631283/Property%20Management/PVFullLogo1.png',
  },
  {
    name: 'PropertyProtect',
    id: 22,
    cta_url: '',
    logo_id: 'v1634243690/Property%20Management/PropertyProtect_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/PropertyProtect_logo.png',
  },
  {
    name: 'Protection Choice',
    id: 23,
    cta_url: '',
    logo_id: 'v1634243690/Property%20Management/protection_choice_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/protection_choice_logo.png',
  },
  {
    name: 'RLL',
    id: 24,
    cta_url: '',
    logo_id: 'v1634243691/Property%20Management/RLL-Logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243691/Property%20Management/RLL-Logo.png',
  },
  {
    name: 'Renters Legal Liability',
    id: 25,
    cta_url: '',
    logo_id: 'v1634243690/Property%20Management/renters_legal_liability_Logos',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/renters_legal_liability_Logos.png',
  },
  {
    name: 'ResidentInsure',
    id: 26,
    cta_url: '',
    logo_id:
      'v1614713428/Property%20Management/Screen_Shot_2021-03-02_at_3.29.19_PM',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1614713428/Property%20Management/Screen_Shot_2021-03-02_at_3.29.19_PM.png',
  },
  {
    name: 'ResidentShield',
    id: 27,
    cta_url: '',
    logo_id: 'v1489605003/preferred_provider_logo_4762',
    logo: 'http://res.cloudinary.com/updater-production/image/upload/v1489605003/preferred_provider_logo_4762.png',
  },
  {
    name: "Roost Renter's Insurance",
    id: 28,
    cta_url: '',
    logo_id: 'v1634243690/Property%20Management/roost-logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/roost-logo.png',
  },
  {
    name: 'SelectQuote',
    id: 29,
    cta_url: '',
    logo_id: 'v1634243690/Property%20Management/selectquote_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/selectquote_logo.png',
  },
  {
    name: 'Skinner Select Master Liability Program',
    id: 30,
    cta_url: '',
    logo_id: 'v1634243690/Property%20Management/mgskinner-logo-',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/mgskinner-logo-.png',
  },
  {
    name: 'State Farm',
    id: 31,
    cta_url: '',
    logo_id: 'v1634243690/Property%20Management/state_farm_logo',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1634243690/Property%20Management/state_farm_logo.png',
  },
  {
    name: 'Stern Risk',
    id: 32,
    cta_url: '',
    logo_id: 'v1613676613/Property%20Management/Stern_Risk',
    logo: 'https://res.cloudinary.com/updater-marketing/image/upload/v1613676613/Property%20Management/Stern_Risk.jpg',
  },
  {
    name: 'Pro Specialty Insurance',
    id: 40,
    cta_url:
      'https://pro-specialty.net/prosweb/Renters/RentResidentSignup.aspx',
    logo_id: 'v1666974841/insurance-logos/pro-specialty',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1666974841/insurance-logos/pro-specialty.png',
  },
  {
    name: 'Select Advantage Insurance Agency',
    id: 41,
    cta_url: 'https://www.selectadvantageinsuranceagency.com',
    logo_id:
      'v1694206307/homeowner_insurance_129915503_Select Advantage Insurance Agency',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1694206307/homeowner_insurance_129915503_Select%20Advantage%20Insurance%20Agency.jpg',
  },
  {
    name: 'Keyes Insurance',
    id: 42,
    cta_url: '',
    logo_id: 'v1661357070/homeowner_insurance_128671183_Keyes Insurance',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1661357070/homeowner_insurance_128671183_Keyes%20Insurance.jpg',
  },
  {
    name: 'Bennett and Porter',
    id: 43,
    cta_url: '',
    logo_id: 'v1673391621/homeowner_insurance_125623510_Bennett and Porter',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1673391621/homeowner_insurance_125623510_Bennett%20and%20Porter.jpg',
  },
  {
    name: 'Captstone Group',
    id: 44,
    cta_url: '',
    logo_id: 'v1678228429/homeowner_insurance_125072280_Captstone Group',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1678228429/homeowner_insurance_125072280_Captstone%20Group.jpg',
  },
  {
    name: 'William Raveis Insurance',
    id: 45,
    cta_url: '',
    logo_id:
      'v1678739230/homeowner_insurance_125215635_William Raveis Insurance',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1678739230/homeowner_insurance_125215635_William%20Raveis%20Insurance.jpg',
  },
  {
    name: 'Stalwart Insurance',
    id: 46,
    cta_url: '',
    logo_id: 'v1673391203/homeowner_insurance_129706619_Stalwart Insurance',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1673391203/homeowner_insurance_129706619_Stalwart%20Insurance.jpg',
  },
  {
    name: 'Edina Realty Insurance',
    id: 47,
    cta_url: '',
    logo_id: 'v1666102733/homeowner_insurance_125469627_Edina Realty Insurance',
    logo: 'https://res.cloudinary.com/updater-production/image/upload/v1666102733/homeowner_insurance_125469627_Edina%20Realty%20Insurance.jpg',
  },
  SURE_PROVIDER,
];

const normalize = (str: string) => str.replace(/[^A-Za-z]/g, '').toLowerCase();

/**
 * Gets the provider data from the INSURANCE_PROVIDERS list.
 * @param {string} providerName The provider's name.
 * @returns {Object | null}
 */
export const getProviderData = (providerName: string) => {
  if (!providerName) {
    return null;
  }

  const foundProvider = INSURANCE_PROVIDERS.find(
    (providerData) => normalize(providerData.name) === normalize(providerName)
  );

  if (foundProvider && foundProvider.logo && foundProvider.name) {
    return foundProvider;
  }
  return null;
};

/**
 * Gets the provider data from the API item_definitions.
 * @param {Provider} provider The provider data.
 * @returns {Object}
 */
export const getProviderDataFromApi = (provider: Provider) => {
  if (provider && provider?.logo_id && provider?.name) {
    return {
      name: provider?.name,
      cta_url: provider?.cta_url,
      logo_id: provider?.logo_id,
      logo: provider?.logo,
    };
  }

  return null;
};
