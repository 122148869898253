import merge from 'lodash/merge';
import { CardDataWithHeroExtras, BadgeConfig } from 'flows/core/types/cards';
import { ItemDefinition, ItemKinds } from 'types';
import { DataForCards } from 'flows/core/hooks/use-data-for-cards';
import { EssentialCards } from 'flows/core/types/layouts';
import { getProviderData } from 'flows/insurance/constants/providers';
import { CLOUDINARY_BASE_URL } from 'flows/insurance/constants';
import { ItemStatuses } from 'constants/statuses';
import { enabledItemDefOfKind, isCompleted, itemOfKind, Badges } from './utils';
import { Item } from 'types/item';
import { Direction } from '@updater/consumer-graph';
import { getUserInfo_getUser_user } from 'types/generated/getUserInfo';

/* Static */
const KIND = ItemKinds.INSURANCE;
const PROOF_OF_INSURANCE_URL_PREFIX = '/resident-onboarding/proof-of-insurance';

const PREFERRED_PROVIDER_TEXT = 'Preferred by your property';
const MOVE_OUT_PROVIDER_TEXT =
  'See an instant quote and purchase protection for your new home.';

const INSURANCE_COMMON_STATIC_DATA = {
  subtype: 'SYI',
  icon: 'SHIELD',
  title: 'Secure renter’s insurance', //default headerText (nonUpo)
  action: {
    type: 'internal',
    route: '/insurance/provider',
  },
} as const;

const INSURANCE_ASSURANT_STATIC_DATA = {
  subtype: 'SYI',
  icon: 'SHIELD',
  title: 'Secure renter’s insurance', //default headerText (nonUpo)
  action: {
    type: 'internal',
    route: '/insurance/assurant',
  },
  description:
    'See an instant quote and purchase protection for your new home.',
} as const;

const INSURANCE_PROMO_STATIC_DATA = {
  ...INSURANCE_COMMON_STATIC_DATA,
  image: {
    url:
      'https://theme.zdassets.com/theme_assets/865430/2e22e0a066bca1cdc2b281ed22a7121709b9316d.png',
    alt: 'sure',
  },
  cost: '8',
} as const;

const INSURANCE_INITIATE_STATIC_DATA = {
  ...INSURANCE_COMMON_STATIC_DATA,
  description:
    'Ensure you have the proper insurance coverage and upload proof.',
} as const;

const INSURANCE_NO_PROOF_INITIATE_STATIC_DATA = {
  ...INSURANCE_COMMON_STATIC_DATA,
  description: 'Ensure you have the proper insurance coverage.',
} as const;

export const statusToBadge = (
  status?: string,
  tab?: string
): BadgeConfig | undefined => {
  if (status === ItemStatuses.STARTED && tab !== 'today') {
    return Badges.RECENTLY_VIEWED;
  }
  switch (status) {
    case ItemStatuses.UNVERIFIED:
      return Badges.ALMOST_DONE;
    case ItemStatuses.VALIDATING:
      return Badges.IN_REVIEW;
    case ItemStatuses.COMPLETED:
      return Badges.DONE;
    case ItemStatuses.INFORMATION_REQUESTED:
    case ItemStatuses.INFO_REQUESTED:
      return Badges.NEEDS_MORE;
    default:
      return undefined;
  }
};

export const statusToImportance = (status?: string) => {
  if (status === 'started') {
    return 750_001;
  }
  if (status === 'completed') {
    return 0;
  }
  return 1_000_000;
};

export const makeCardJson = (
  status: ItemStatuses,
  commonData: any,
  payloadKind: 'INITIATE' | 'PROMOTED'
): CardDataWithHeroExtras => {
  return {
    type: payloadKind,
    data: {
      ...commonData,
      badge: statusToBadge(status, 'today'),
    },
    heroExtras: {
      heading: 'Get insurance coverage to keep your stuff safe. ',
      ctaText: 'Get started',
    },
    identifier: `syi_${payloadKind.toLowerCase()}`,
    importanceScore: statusToImportance(status),
  };
};

export const getCombinedCardsInfo = ({
  insuranceItem,
  proofOfInsuranceItem,
}: {
  insuranceItem: Item;
  proofOfInsuranceItem: Item;
}): { status: string; route: string } => {
  let status = proofOfInsuranceItem?.status;
  let route = '/insurance/provider';
  switch (proofOfInsuranceItem?.status) {
    case ItemStatuses.COMPLETED:
    case ItemStatuses.VALIDATING:
      route = `${PROOF_OF_INSURANCE_URL_PREFIX}/thank-you`; // TODO:  replace with receipt page
      break;
    case ItemStatuses.INFORMATION_REQUESTED:
    case ItemStatuses.INFO_REQUESTED:
      route = `${PROOF_OF_INSURANCE_URL_PREFIX}/rejection`;
      break;
    default:
      status = insuranceItem?.status;
      route = '/insurance/landing';

      break;
  }
  return { status, route };
};

export const getCardRoute = (item: Item): string => {
  if (
    item?.metadata &&
    Object.prototype.hasOwnProperty.call(
      item?.metadata,
      'completed_insurance_type'
    )
  ) {
    switch (item.metadata.completed_insurance_type) {
      case 'assurant_pol':
        return 'insurance/assurant/standard/receipt';
      case 'assurant_poi':
        return 'insurance/assurant/upload/receipt';
      default:
        return 'insurance/provider';
    }
  }
  return 'insurance/provider';
};

/* TODAY CARD */
export const makeTodayTabInsuranceCards = (
  { itemDefinitions, items, user, isGreystarPilotEnabled }: DataForCards,
  plansData?: any
): CardDataWithHeroExtras[] => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const proofOfInsuranceItemDef = itemDefinitions.find(
    enabledItemDefOfKind(ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_INSURANCE)
  );
  const insuranceItems = items.filter(itemOfKind(KIND));
  if (user?.currentMove?.toAddress.ownership === 'OWN') return [];

  const isMoveOut = user?.currentMove?.direction === Direction.move_out;
  if (!plansData) return [];
  if (itemDef) {
    const [proofOfInsuranceItem] = items.filter(
      itemOfKind(ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_INSURANCE)
    );

    const { status, route } = proofOfInsuranceItemDef
      ? getCombinedCardsInfo({
          insuranceItem: insuranceItems[0],
          proofOfInsuranceItem,
        })
      : {
          status: insuranceItems[0]?.status,
          route: getCardRoute(insuranceItems[0]),
        };

    if (isGreystarPilotEnabled) {
      const card = makeCardJson(
        status,
        { ...INSURANCE_ASSURANT_STATIC_DATA },
        'PROMOTED'
      );
      const preferredProviderJson = merge(card, {
        data: {
          image: {
            url: itemDef?.page?.logo_id
              ? `${CLOUDINARY_BASE_URL}${encodeURI(itemDef.page.logo_id)}`
              : null,
            alt: 'Assurant',
          },
          description: PREFERRED_PROVIDER_TEXT,
        },
      });
      return [preferredProviderJson];
    }

    const cardJson = makeCardJson(
      status,
      {
        ...INSURANCE_COMMON_STATIC_DATA,
        action: {
          ...INSURANCE_COMMON_STATIC_DATA.action,
          route: isGreystarPilotEnabled ? '/insurance/assurant' : route,
        },
      },
      'PROMOTED'
    );

    const providerData = getProviderData(itemDef?.page?.name || '') || {
      ...itemDef.page,
      logo: itemDef?.page?.logo_id
        ? `${CLOUDINARY_BASE_URL}${encodeURI(itemDef.page.logo_id)}`
        : null,
    };
    const preferredProviderJson = merge(cardJson, {
      data: {
        image: providerData?.logo
          ? { url: providerData.logo, alt: providerData.name }
          : {},
        description: isMoveOut
          ? MOVE_OUT_PROVIDER_TEXT
          : PREFERRED_PROVIDER_TEXT,
      },
    });
    return [preferredProviderJson];
  }

  return [];
};

const essentialsImportanceScore = 1_000_000;
const essentialsType = 'INITIATE';
const essentialsIdentifier = 'syi_initiate';

export const isItemRequired = (
  user: getUserInfo_getUser_user | undefined,
  itemDef: ItemDefinition,
  proofItem: any
) => {
  if (user?.currentMove?.direction === Direction.move_out) return false;

  return !!proofItem || !!itemDef?.page?.required || !!itemDef?.page?.assurant;
};

/* Essentials Card */
export const makeEssentialsTabInsuranceCards = ({
  itemDefinitions,
  items,
  user,
  isGreystarPilotEnabled,
}: DataForCards): EssentialCards => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  // we always reference just the first insurance item
  const insuranceItem = items.filter(itemOfKind(KIND))[0];

  const proofOfInsuranceItemDef = itemDefinitions.find(
    enabledItemDefOfKind(ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_INSURANCE)
  );

  // Determine if user is UPO mover - (Different text)
  const isUPOMove = Boolean(user?.currentMove?.residentOnboardingEnabled);

  const essentialCardLists: EssentialCards = {
    toDoCards: [],
    completedCards: [],
  };
  if (itemDef && user?.currentMove?.toAddress.ownership !== 'OWN') {
    const showAssurant = isGreystarPilotEnabled;

    const [proofOfInsuranceItem] = items.filter(
      itemOfKind(ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_INSURANCE)
    );
    const assurantPolEnabled = !!itemDef.page?.assurant;

    const { status, route } =
      proofOfInsuranceItemDef && !assurantPolEnabled
        ? getCombinedCardsInfo({
            insuranceItem,
            proofOfInsuranceItem,
          })
        : {
            status: insuranceItem?.status,
            route: getCardRoute(insuranceItem),
          };

    if (showAssurant) {
      return makeAssurantEssentialsCard({ status });
    }

    // the insurance item is required when:
    //  - user is UPO and POI is required
    //  - user is UPO and Insurance is required
    //  - Insurance is required
    //  - user is moving into one of the Greystar pilot properties
    //  - user is assurant eligible
    const required = isItemRequired(user, itemDef, proofOfInsuranceItem);

    /* If user is UPO, the Header is different */
    const headerText = isUPOMove
      ? 'Secure renters insurance'
      : 'Secure renter’s insurance';

    // Set the static data including the derived headerText (title)
    const insuranceStaticData = {
      ...(isUPOMove
        ? { ...INSURANCE_INITIATE_STATIC_DATA, title: headerText }
        : { ...INSURANCE_NO_PROOF_INITIATE_STATIC_DATA, title: headerText }),
      action: {
        ...INSURANCE_INITIATE_STATIC_DATA.action,
        route,
      },
    };
    const targetList = isCompleted({ status }) ? 'completedCards' : 'toDoCards';
    essentialCardLists[targetList].push({
      type: essentialsType,
      data: {
        ...insuranceStaticData,
        // TODO: add badge logic back when we decide on implementation for ROB + Assurant
        badge:
          isCompleted({ status }) || proofOfInsuranceItemDef
            ? statusToBadge(status)
            : undefined,
        required,
      },
      identifier: `${essentialsIdentifier}${
        isCompleted({ status }) ? '_completed' : ''
      }${insuranceItem ? `_${insuranceItem.id}` : ''}`,
      importanceScore: essentialsImportanceScore,
    });
  }
  return essentialCardLists;
};
export function makeAssurantEssentialsCard({
  status,
}: {
  status: string;
}): EssentialCards {
  const essentialCardLists: EssentialCards = {
    toDoCards: [],
    completedCards: [],
  };
  const targetList = isCompleted({ status }) ? 'completedCards' : 'toDoCards';
  essentialCardLists[targetList].push({
    type: essentialsType,
    data: {
      ...INSURANCE_ASSURANT_STATIC_DATA,
      required: true,
    },
    identifier: essentialsIdentifier,
    importanceScore: essentialsImportanceScore,
  });
  return essentialCardLists;
}
