import { useMemo } from 'react';
import { useSWRWhenAuthed } from 'utils/use-swr-when-authed';

interface DataForLinkout {
  title: string;
  baseInstructions: string;
  prefacingQuestion?: string;
  submitButtonText?: string;
}

type LinkoutFlowConfigData = {
  data?: DataForLinkout;
  loading: boolean;
  error?: any;
};

export const LINKOUT_SUBMIT_BUTTON_TEXT = 'All done!';

export const makeLinkoutCardData = (flowType: string, isUPO: boolean) => {
  switch (flowType) {
    case 'resident-link':
      return {
        title: 'Report your rent',
        description:
          'Report positive rent payments to the major bureaus to build your credit file.',
        icon: 'RECEIPT',
      };
    case 'key-pickup':
      return {
        title: 'Schedule key pick-up',
        description: 'Pick a time to get your keys.',
        icon: 'KEY',
      };
    case 'elevator-scheduling':
      return {
        title: 'Reserve an elevator',
        description: isUPO
          ? 'Secure the elevator for move-in day, or confirm you don’t need this.'
          : 'Secure the elevator for any deliveries on move-in day.',
        icon: 'ARROWS_DOWN_UP',
      };
    case 'loading-dock-reservation':
      return {
        title: isUPO ? 'Reserve the loading dock' : 'Reserve a loading dock',
        description: isUPO
          ? 'Reserve a time window, or confirm you don’t need this.'
          : 'Reserve a loading dock window on move-in day. ',
        icon: 'CALENDAR',
      };
    case 'packages-and-deliveries':
      return {
        title: isUPO ? 'Set up packages & deliveries' : 'Packages & deliveries',
        description: 'Sign up to receive packages & deliveries.',
        icon: 'BOX',
      };
    case 'security-deposit-alternative':
      return {
        title: 'Security deposit alternative',
        description: 'Learn about other ways to pay your security deposit.',
        icon: 'WALLET',
      };
    case 'download-community-app':
      return {
        title: 'Download community app',
        description: 'Access key community applications.',
        icon: 'DOWNLOAD',
      };
    case 'pet-screening':
      return {
        title: 'Register your pet with PetScreening®',
        description: 'Complete PetScreening® registration',
        icon: 'PAW',
      };
    default:
      console.error(`Missing cardData configuration for ${flowType}`);
      return { title: "Let's get started", description: '', icon: 'KEY' };
  }
};

export const useLinkoutConfig = (flowType: string): LinkoutFlowConfigData => {
  const { data: itemDefinitionsData, error: itemDefinitionsError } =
    useSWRWhenAuthed('/v2/item_definitions');
  const flowPrefix = 'resident_onboarding-';
  const flowSuffix = flowType.replace(flowPrefix, '');
  return useMemo(() => {
    if (itemDefinitionsError) {
      return { loading: false, error: itemDefinitionsError };
    }
    if (itemDefinitionsData) {
      const foundItem = itemDefinitionsData.find(
        (item: any) => item.code === flowType
      );
      const propertyName = foundItem?.entity?.name;

      switch (flowSuffix) {
        case 'resident-link':
          return {
            data: {
              title: 'Report your rent',
              baseInstructions:
                'Your positive rent payments can be reported to the major bureaus to build your credit. Visit the link below.',
              prefacingQuestion: '',
            },
            loading: false,
          };
        case 'key-pickup':
          return {
            data: {
              title: 'Schedule your key pickup',
              baseInstructions: `Click below to schedule your key pickup with ${propertyName} . Once you've finished, click "${LINKOUT_SUBMIT_BUTTON_TEXT}" below.`,
              submitButtonText: LINKOUT_SUBMIT_BUTTON_TEXT,
            },
            loading: false,
          };
        case 'elevator-scheduling':
          return {
            data: {
              title: 'Reserve an elevator',
              baseInstructions: `Click below to reserve an elevator for important deliveries on move-in day. Click "${LINKOUT_SUBMIT_BUTTON_TEXT}" below.`,
              submitButtonText: LINKOUT_SUBMIT_BUTTON_TEXT,
            },
            loading: false,
          };
        case 'lease-signing':
        case 'loading-dock-reservation':
          return {
            data: {
              title: 'Reserve a loading dock',
              baseInstructions: `Click below to reserve a loading dock on your move-in day. Once you've finished, click "${LINKOUT_SUBMIT_BUTTON_TEXT}" below.`,
              submitButtonText: LINKOUT_SUBMIT_BUTTON_TEXT,
            },
            loading: false,
          };
        case 'packages-and-deliveries':
          return {
            data: {
              title: 'Sign up to receive packages & deliveries',
              baseInstructions: `Click below to sign up for the package and delivery program. Once you've finished, click "${LINKOUT_SUBMIT_BUTTON_TEXT}" below so we can review.`,
              submitButtonText: LINKOUT_SUBMIT_BUTTON_TEXT,
              prefacingQuestion: '',
            },
            loading: false,
          };
        case 'security-deposit-alternative':
          return {
            data: {
              title: 'There are other ways to pay your security deposit!',
              baseInstructions: '',
              submitButtonText: LINKOUT_SUBMIT_BUTTON_TEXT,
              prefacingQuestion:
                'Are you interested in a security deposit alternative?',
            },
            loading: false,
          };
        case 'download-community-app':
          return {
            data: {
              title: 'Download your community’s application',
              baseInstructions: `Click below to download your community’s applications ahead of your move-in day. Once you've finished, click "${LINKOUT_SUBMIT_BUTTON_TEXT}" below.`,
              submitButtonText: LINKOUT_SUBMIT_BUTTON_TEXT,
              prefacingQuestion: '',
            },
            loading: false,
          };
        case 'pet-screening':
          return {
            data: {
              title: 'Register your pet with PetScreening®',
              baseInstructions: `${propertyName} partners with petscreening.com to ensure full compliance`,
              prefacingQuestion: '',
              submitButtonText: LINKOUT_SUBMIT_BUTTON_TEXT,
            },
            loading: false,
          };
        default:
          return {
            data: {
              title: 'Register',
              baseInstructions: 'Visit the link below',
              prefacingQuestion: '',
            },
            loading: false,
          };
      }
    }
    return { loading: true };
  }, [flowType, flowSuffix, itemDefinitionsData, itemDefinitionsError]);
};
